<template>
  <div
    class="DateTest"
    :class="{ fadeOut }">
    <div class="spacerTop" />
    <div
      ref="time"
      class="time"
      :class="{ feedbackCorrect, singleRow }">
      <Date
        v-if="date"
        v-bind="date"
        xshow-labels
        :focus="focus.date"
        @request-change="(attr, value) => (date[attr] = value)"
        @request-focus="(attr, index) => setFocus(0, attr, index)" />
      <div
        v-if="singleRow && endDate"
        class="separator">
        —
      </div>
      <Date
        v-if="endDate"
        v-bind="endDate"
        :focus="focus.endDate"
        @request-change="(attr, value) => (endDate[attr] = value)"
        @request-focus="(attr, index) => setFocus(1, attr, index)" />
    </div>
    <div class="spacer" />

    <DigitPad
      class="DigitPad"
      @click-digit="setDigit" />
  </div>
</template>

<script>
import { TimelineMax, Power2 } from 'gsap';

import soundService from '@/services/sound-service';
import correctSound from '@/assets/sounds/correct-answer.mp3';
import DigitPad from '@/modules/games-shared/components/DigitPad';
import Date from './Date';

export default {
    components: { DigitPad, Date },
    inject: ['transition', 'module'],
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    data() {
        function extract(date) {
            if (!date) {
                return undefined;
            }
            let struct = {};
            if (date.year) {
                struct.year = '----';
            }
            if (date.month) {
                struct.month = '--';
            }
            if (date.day) {
                struct.day = '--';
            }
            return struct;
        }

        return {
            date: extract(this.data.fasit.date),
            endDate: extract(this.data.fasit.endDate),
            focusIndex: [0, 0],
            feedbackCorrect: false,
            fadeOut: false
        };
    },
    computed: {
        session() {
            return this.module.state;
        },
        focusGrid() {
            const grid = [];
            if (this.date) {
                grid.push([
                    ...(this.date.year ? ['date.year.0', 'date.year.1', 'date.year.2', 'date.year.3'] : []),
                    ...(this.date.month ? ['date.month.0', 'date.month.1'] : []),
                    ...(this.date.day ? ['date.day.0', 'date.day.1'] : [])
                ]);
            }
            if (this.endDate) {
                grid.push([
                    ...(this.endDate.year
                        ? ['endDate.year.0', 'endDate.year.1', 'endDate.year.2', 'endDate.year.3']
                        : []),
                    ...(this.endDate.month ? ['endDate.month.0', 'endDate.month.1'] : []),
                    ...(this.endDate.day ? ['endDate.day.0', 'endDate.day.1'] : [])
                ]);
            }
            return grid;
        },
        focus() {
            const focus = {
                date: {},
                endDate: {}
            };
            if (this.focusIndex) {
                const [rowIndex, cellIndex] = this.focusIndex;
                const path = this.focusGrid[rowIndex][cellIndex];
                const [attr1, attr2, index] = path.split('.');
                focus[attr1][attr2] = parseInt(index, 0);
            }

            return focus;
        },
        allFilled() {
            function allFilled(date) {
                if (!date) {
                    return true;
                }
                return (
                    (date.year || '').indexOf('-') + (date.month || '').indexOf('-') + (date.day || '').indexOf('-') ===
                    -3
                );
            }
            return allFilled(this.date) && allFilled(this.endDate);
        },
        allCorrect() {
            if (!this.allFilled) {
                return false;
            }
            function equal(a, b) {
                if (typeof a === 'object' && typeof b === 'object') {
                    return equal(a.year, b.year) && equal(a.month, b.month) && equal(a.day, b.day);
                }
                return a === b;
            }
            return equal(this.date, this.data.fasit.date) && equal(this.endDate, this.data.fasit.endDate);
        },
        singleRow() {
            const isTimespan = this.date && this.endDate;
            return !(isTimespan && this.date.month);
        }
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    soundService.play(correctSound);
                    this.playFeedbackCorrect().then(() => {
                        this.fadeOut = true;
                        // this.$emit('feedback-completed')
                        setTimeout(() => this.$emit('feedback-completed'), 1000);
                    });
                } else {
                    this.playFeedbackWrong().then(() => {
                        this.$emit('feedback-completed');
                    });
                }
            });
        },
        allFilled(flag) {
            if (flag) {
                this.transition('SUBMIT_ANSWER', { value: { date: this.date, endDate: this.endDate } });
            }
        }
    },
    beforeDestroy() {
        if (this.tl) {
            this.tl.kill();
        }
    },
    methods: {
        setDigit(digit) {
            if (!this.focusIndex) {
                return;
            }
            if (digit === '<') {
                return this.moveFocusLeft();
            } else if (digit === '>') {
                return this.moveFocusRight();
            }
            const [rowIndex, cellIndex] = this.focusIndex;
            const path = this.focusGrid[rowIndex][cellIndex];
            const [attr1, attr2, index] = path.split('.');

            let digits = this[attr1][attr2].split('');
            if (digit === 'x') {
                digit = '-';
            }
            digits[index] = digit;
            this[attr1][attr2] = digits.join('');

            if (digit === '-') {
                return;
            }
            if (this.allFilled) {
                this.focusIndex = null;
            } else {
                this.moveFocusRight();
            }
        },
        setFocus(rowIndex, attr2, index) {
            const pathEnd = `.${attr2}.${index}`;
            this.focusIndex = [rowIndex, this.focusGrid[rowIndex].findIndex(path => path.endsWith(pathEnd))];
        },
        moveFocusLeft() {
            const [rowIndex, cellIndex] = this.focusIndex;
            if (cellIndex > 0) {
                this.focusIndex = [rowIndex, cellIndex - 1];
            } else if (rowIndex > 0) {
                this.focusIndex = [rowIndex - 1, this.focusGrid[rowIndex - 1].length - 1];
            }
        },
        moveFocusRight() {
            const [rowIndex, cellIndex] = this.focusIndex;
            if (cellIndex < this.focusGrid[rowIndex].length - 1) {
                this.focusIndex = [rowIndex, cellIndex + 1];
            } else if (rowIndex < this.focusGrid.length - 1) {
                this.focusIndex = [rowIndex + 1, 0];
            }
        },
        cheat() {
            this.focusGrid.forEach(row => {
                row.forEach(path => {
                    const [attr1, attr2, index] = path.split('.');
                    if (this[attr1][attr2][index] === '-') {
                        let digits = this[attr1][attr2].split('');
                        digits[index] = this.data.fasit[attr1][attr2][index];
                        this[attr1][attr2] = digits.join('');
                    }
                });
            });
        },
        playFeedbackCorrect() {
            // return new Promise(resolve => {
            //   this.feedbackCorrect = true
            //   setTimeout(() => {
            //     resolve()
            //   }, 2000)
            // })
            return new Promise(resolve => {
                let elementsToAnimate = this.$refs.time;
                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1.1,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToAnimate, 0.3, {
                    scale: 1,
                    ease: Power2.easeInOut
                });
            });
        },
        playFeedbackWrong() {
            return new Promise(resolve => {
                let shakeSpeed = 0.1;
                let elementsToShake = this.$refs.time;

                let tl = (this.tl = new TimelineMax({ onComplete: () => resolve() }));
                tl.to(elementsToShake, shakeSpeed, {
                    x: -7,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    repeat: 4,
                    x: 5,
                    yoyo: true,
                    ease: Power2.easeInOut
                });
                tl.to(elementsToShake, shakeSpeed, {
                    x: 0
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.DateTest {
    display: flex;
    flex-direction: column;

    &.fadeOut {
        transition: opacity 0.5s;
        opacity: 0;
    }
}

.spacerTop {
    flex: 1;
}
.spacer {
    min-height: 1em;
    flex: 1;
}
.time {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;

    // &.feedbackCorrect {
    //   /deep/ .Digit {
    //     background-color: green !important;
    //   }
    // }

    &.singleRow {
        flex-direction: row;
        justify-content: center;
    }
    &:not(.singleRow) {
        .Date:not(:first-child) {
            margin-top: 1em;
        }
    }
}
.timeNEW {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.endDateLabel {
    font-size: 80%;
    color: rgba(black, 0.5);
    margin-bottom: 0.4em;
    margin-top: 1em;
}
.Date:not(:first-child) {
    // margin-top: 0.5em;
}
.DigitPad {
    margin: 0 2em;
}
.separator {
    margin: 0 1em;
    color: rgba(black, 0.4);
}
</style>
