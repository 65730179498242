<template>
  <div class="Question">
    <div
      class="question"
      :style="{ color: theme.primaryColor }">
      {{ $t('currentGame.Question.question') }}
    </div>

    <div class="Context">
      <TransitionExpandCollapse>
        <div v-if="showFasit">
          <div class="Context__time">
            {{ question | stringifyTime }}
          </div>
          <div class="Context__divider" />
        </div>
      </TransitionExpandCollapse>
      <div class="Context__text">
        {{ question.text }}
      </div>
    </div>
  </div>
</template>

<script>
import last from 'lodash-es/last';
import TransitionExpandCollapse from '@/components/TransitionExpandCollapse';
import { stringifyTime } from '../utils';

export default {
    components: { TransitionExpandCollapse },
    filters: {
        stringifyTime: function (value) {
            return stringifyTime(value);
        }
    },
    props: {
        itemIndex: {
            type: Number,
            required: true
        }
    },
    inject: ['module', 'theme'],
    data() {
        return {
            showFasit: false
        };
    },
    computed: {
        session() {
            return this.module.state;
        },
        quizItem() {
            return this.module.getters('quiz/getItem')(this.itemIndex);
        },
        currentTest() {
            return last(this.quizItem.tests);
        },
        currentTestName() {
            return last(this.quizItem.tests).name;
        },
        question() {
            return this.session.quiz.sourceItems[this.quizItem.sourceItemIndex].data;
        }
    },
    watch: {
        'currentTest.done': function (val) {
            if (this.currentTest.score) {
                setTimeout(() => {
                    this.showFasit = true;
                }, 1000);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.Question {
    // padding: 1em;
    display: flex;
    flex-direction: column;
    padding: 0 2em;
}
.question {
    font-size: 120%;
    text-align: center;
}

.Context {
    padding: 1em;
    background-color: white; //rgba(white, 0.8);
    color: black;
    border-radius: 0.5em;
    // min-width: 20em;
    align-self: stretch;

    // margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;

    box-shadow: 0 1em 1em rgba(black, 0.2);
}
.Context__divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
.Context__text {
    line-height: 1.5em;
    color: mix(black, white, 80%);
    font-size: 120%;
}
.Context__time {
    font-size: 140%;
    text-align: center;
}
</style>
