<template>
  <div
    class="MultipleChoiceDateTest"
    :class="{ fadeOut }">
    <MultipleChoiceInputStandard
      ref="input"
      :items="data.options"
      :selections="selectedFragmentIndices"
      @item-click="handleItemClick" />
  </div>
</template>

<script>
import MultipleChoiceInputStandard from '@/modules/games-shared/components/MultipleChoiceInputStandard';

export default {
    components: { MultipleChoiceInputStandard },
    inject: ['transition'],
    props: {
        done: {
            type: Boolean
        },
        score: {
            type: Number
        },
        data: {
            type: Object
        }
    },
    data() {
        return {
            selectedFragmentIndices: [],
            fadeOut: false
        };
    },
    watch: {
        done() {
            this.$nextTick(() => {
                if (this.score === 1) {
                    this.$refs.input.playFeedbackCorrect().then(() => {
                        this.fadeOut = true;
                        setTimeout(() => this.$emit('feedback-completed'), 1000);
                        // this.$emit('feedback-completed')
                    });
                } else {
                    this.$refs.input.playFeedbackWrong().then(() => {
                        // setTimeout(() => this.$emit('feedback-completed'), 2000)
                        this.$emit('feedback-completed');
                    });
                }
            });
        }
    },
    methods: {
        handleItemClick(optionIndex) {
            this.selectedFragmentIndices.push(optionIndex);
            this.transition('SUBMIT_ANSWER', { value: this.data.options[optionIndex] });
        },
        cheat() {
            this.selectedFragmentIndices = [0];
            this.transition('SUBMIT_ANSWER', { value: this.data.fasit });
        }
    }
};
</script>

<style scoped lang="scss">
.MultipleChoiceDateTest {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.fadeOut {
        transition: opacity 0.5s;
        opacity: 0;
    }
}
.feedback {
    padding: 1em;
    background-color: red;

    &.correct {
        background-color: green;
    }
}
</style>
