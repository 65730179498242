export default [
    { en: 'The Marsian War.', no: 'Marskrigen' },
    { en: 'The Moon conflict.', no: 'Månekonflikten.' },
    { en: 'Subterra War 1.', no: 'Subterra Krig 1.' },
    { en: 'Multi-dimension War 1.', no: 'Multi-dimensjonskrig 1.' },
    { en: 'Russian-Chinese union period.', no: 'Russisk-kinesisk foreningsperiode.' },
    { en: 'Mars-Earth union period.', no: 'Mars-Jorden unionsperiode.' },
    { en: 'African-Indian union period.', no: 'Afrikansk-indisk unionsperiode.' },
    { en: 'Scandinavian union period.', no: 'Skandinavisk unionsperiode.' },
    { en: 'Monsterwave period.', no: 'Monsterbølgeperioden.' },
    { en: 'Alien invasion.', no: 'Alien invasjon.' },
    { en: 'Mutant revolution.', no: 'Mutant revolusjon.' },
    {
        en: 'The drug liberation & crime removal experiments.',
        no: 'Narkotikafrigjørings- og kriminalitetsfjerningseksperimentene.'
    },
    { en: 'World military union.', no: 'Verdens militærunion.' },
    { en: 'Worldwide Happiness festival.', no: 'Verdensomspennende lykkefestival.' },
    { en: 'Flower Power 2.', no: 'Flower Power 2.' },
    { en: 'Love Parade 2.', no: 'Love Parade 2.' },
    { en: 'Moon colonization.', no: 'Månekolonisering.' },
    { en: 'Subterra colonization.', no: 'Underjordisk kolonisering.' },
    { en: 'Subsea colonization.', no: 'Undersjøisk kolonisering.' },
    { en: 'Atmospheric colonization.', no: 'Atmosfærisk kolonisering.' },
    { en: 'Hobbits visit earth for the first time.', no: 'Hobbiter besøker jorden for første gang.' },
    { en: 'Hobbits visit earth for the second time.', no: 'Hobbiter besøker jorden for andre gang.' },
    { en: "Ixus the terrible's reign.", no: 'Ixus den forferdeliges regjeringstid.' },
    { en: 'Roman Empire 2.', no: 'Romerriket 2.' },
    { en: 'World cycling tour.', no: 'Verdensomspennende sykkeltur.' },
    { en: 'World jogging tour.', no: 'Verdensomspennende joggetur.' },
    { en: 'The New Ice Age.', no: 'Den Nye Istiden.' },
    { en: 'Coffee domination period', no: 'Kaffedominansperioden.' },
    { en: 'Dinasours reconquer the earth.', no: 'Dinasourene gjenerobrer jorden.' },
    { en: 'The humanoid society.', no: 'Humanoide samfunn.' },
    { en: 'Alien druglords rule the US.', no: 'Fremmede narkotikabaroner styrer USA.' },
    { en: 'The life of Ixus the allmighty king.', no: 'Livet til Ixus den allmektig kongen.' },
    { en: 'The life of the Juvesian emperor Hokkaslides.', no: 'Livet til den Juvesiske keiseren Hokkaslides.' },
    {
        en: 'The life of the scientist Ukutu, who developed anti-gravity technology.',
        no: 'Livet til forskeren Ukutu, som utviklet anti-tyngdekraft teknologi.'
    },
    { en: 'The life of the Native American athlete Fast Bearman.', no: 'Livet til indianeren Fast Bearman.' },
    { en: 'The life of the dolphin Flapper.', no: 'Livet til delfinen Flapper.' },
    { en: 'The life of Brian.', no: 'Livet til Brian.' },
    { en: 'The international free hugs campaign.', no: 'Den internasjonale gratis klemme-kampanjen.' },
    { en: 'The nuclear warhead dissolution years.', no: 'De kjernefysiske stridshodeoppløsningsårene.' },
    { en: 'The reign of Putin the 2nd.', no: 'Regimet til Putin den 2.' },
    { en: 'The legal period of see-through technology.', no: 'Den juridiske periode for gjennomsiktighetsteknologi.' },
    { en: 'The volcano years.', no: 'Vulkanens år.' },
    { en: 'The platinum ages.', no: 'Platinatiden.' },
    { en: 'World War 3.', no: '3. verdenskrig.' },
    { en: 'The EU civil kindness act.', no: 'EUs sivile godhetsreform.' },
    { en: 'Star War 1.', no: 'Første Stjernekrig.' },
    { en: 'Star War 2.', no: 'Andre Stjernekrig.' },
    { en: 'Star War 3.', no: 'Tredje Stjernekrig.' },
    { en: 'The era of happiness.', no: 'Epoken med lykke.' },
    { en: 'The flipped classroom era.', no: 'Det omvendte klasserommets æra.' },
    { en: 'The terracotta army strikes back.', no: 'Terrakottahæren slår tilbake.' },
    { en: 'The international intrigue period.', no: 'Den internasjonale intrigers periode.' },
    { en: 'Fashion is out of fashion.', no: 'Mote er ute av moten.' },
    { en: 'The revival of books.', no: 'Gjenopplivingen av bøker.' },
    {
        en: 'Sound circles in South-Africa energize the continent.',
        no: 'Lydsirkler i Sør-Afrika gir energi til kontinentet.'
    },
    { en: 'The king of happiness rules the earth.', no: 'Kongen av lykke regjerer jorden.' },
    {
        en: 'A 10-year old prodigy controls all social media.',
        no: 'Et 10-år gammelt vidunderbarn kontrollerer alle sosiale medier.'
    },
    { en: 'The Illuminati Revolution.', no: 'Illuminati-revolusjonen.' },
    { en: 'The dragon years.', no: 'Dragens år.' },
    { en: 'The galaxy union period.', no: 'Galaksens unionperiode.' },
    { en: 'The smurfs visit earth.', no: 'Smurfene besøker jorden.' },
    { en: 'The elves appear from another dimension.', no: 'Alvene dukker opp fra en annen dimensjon.' },
    { en: 'Programmed winds influence peoples thoughts.', no: 'Programmert vind påvirker folks tanker.' },
    { en: 'World Peace 1.', no: 'Verdensfred 1.' },
    { en: 'World Peace 2.', no: 'Verdensfred 2.' },
    { en: 'World Peace 3.', no: 'Verdensfred 3.' },
    { en: 'Afterlife portal visible in Antarctica.', no: 'Etterlivsportal blir synlig i Antarktis.' },
    { en: 'Global 1-child policy period.', no: 'Global ettbarnsperiode.' },
    { en: 'The spiritual enlightenment years.', no: 'De åndelige opplysningsårene.' },
    { en: 'The 6th generation humans created the Pyramids', no: 'Sjette generasjons menneskene skapte pyramidene.' },
    {
        en: 'Annunaki aliens combine their own DNA with Homos Erectus to create the 1st generation of humans on earth.',
        no:
            'Annunaki aliens kombinerer sitt eget DNA med Homos Erectus for å lage første generasjon mennesker på jorden.'
    },
    {
        en: 'The human slave colonies rebel against their alien masters.',
        no: 'De menneskelige slavekoloniene gjør opprør mot aliens.'
    },
    { en: "Prince serves as the world's music minister.", no: 'Prince sin periode som verdens musikkminister.' },
    { en: 'Collective conscience years.', no: 'Felles samvittighetsår.' },
    {
        en: 'The internet is closed down and re-emerges separately in different countries.',
        no: 'Internett er nedlagt og gjenoppstår separat i forskjellige land.'
    },
    { en: 'The life of the Panda Bear protector.', no: 'Livet til pandabjørnens beskytter.' },
    { en: 'The life of the Giant Ant.', no: 'Livet til kjempemauren.' },
    { en: 'The second life of Sherlock Holmes.', no: 'Det andre livet til Sherlock Holmes.' },
    { en: 'The second life of Jack The Ripper.', no: 'Det andre livet til Jack The Ripper.' },
    { en: 'The second life of Bruce Lee.', no: 'Det andre livet til Bruce Lee.' },
    { en: 'The second life of Nicholas Tesla.', no: 'Den andre livet Nicholas Tesla.' },
    { en: 'Mars terraforming period.', no: 'Mars sin terraformingperiode.' },
    { en: 'The second life of Elvis Presley.', no: 'Det andre livet til Elvis Presley.' },
    { en: 'The life of Gandalf.', no: 'Livet til Gandalf.' },
    { en: 'The life of Spock.', no: 'Livet til Spock.' },
    { en: 'The life of Ironman.', no: 'Livet til Ironman.' },
    { en: 'The life of Catwoman.', no: 'Livet til Catwoman.' },
    {
        en: 'The 7th generation humans are placed on earth.',
        no: 'Den syvende generasjon mennesker plassert på jorden.'
    },
    {
        en: 'The 1st generation aliens create peace on earth.',
        no: 'Den første generasjons aliens skaper fred på jorden.'
    },
    { en: 'The first world presidential period.', no: 'Verdens første presidentperiode.' },
    { en: 'The life of the first world king.', no: 'Livet til den første verdenskongen.' },
    { en: 'The life of the first world queen.', no: 'Livet til den første verdensdronningen.' },
    { en: 'The visionary period.', no: 'Den visjonære perioden.' },
    { en: 'The meditation period.', no: 'Meditasjonsperioden.' },
    { en: 'The second life of Caesar.', no: 'Det andre livet til Caesar.' },
    { en: 'The life of Han Solo.', no: 'Livet til Han Solo.' },
    { en: 'The life of Luke Skywalker.', no: 'Livet til Luke Skywalker.' },
    { en: 'The life of Neytiri.', no: 'Livet til Neytiri.' },
    { en: 'The oil wars.', no: 'Oljekrigene' },
    { en: 'The tropical era.', no: 'Den tropiske æra.' },
    { en: 'The cloudy haze era.', no: 'Den overskyete æra.' },
    { en: 'The rainy era.', no: 'Regntiden.' },
    { en: 'The hover life years.', no: 'Sveveårene.' },
    { en: 'The underground life years.', no: 'De underjordiske leveårene.' },
    { en: 'The aftermath years.', no: 'Etterutslettelsestiden.' },
    { en: 'The guru comedy years.', no: 'Guru komedieårene.' },
    { en: 'Time stop period.', no: 'Tidsstopperioden.' },
    { en: 'New world religion era.', no: 'Den nye verdensreligionsæraen.' },
    { en: 'Second Middle Ages.', no: 'Den andre middelalderen.' },
    { en: 'The New Renaissance.', no: 'Den nye renaissancen.' },
    { en: 'The Age of Individualism.', no: 'Individualismens æra.' },
    { en: 'The TV-Series years.', no: 'TV-seriens år.' },
    { en: 'The alcohol abolition years.', no: 'Alkoholforbudets tid.' },
    { en: 'The smoking abolition years.', no: 'Røykeforbudets tid.' },
    { en: 'The Religion Wars.', no: 'Religionskrigene.' },
    { en: 'The life of Hydra the Merciful.', no: 'Livet til Hydra den barmhjertige.' },
    { en: 'The life of Otta the Cruel.', no: 'Livet til Otta den grusomme.' },
    { en: 'The life of Chinga the Beautiful.', no: 'Livet til Chinga den vakre.' },
    { en: 'The life of Assak the Thirsty.', no: 'Livet til Assak den tørste.' },
    { en: 'The life of Corpa the Vain.', no: 'Livet til Corpa den forfengelige.' },
    { en: 'The life of Hilda the Fair.', no: 'Livet til Hilda den lyse.' },
    { en: 'The life of Trina the Tyrant.', no: 'Livet til Trina tyrann.' },
    { en: 'The voodoo period.', no: 'Voodoo-perioden.' },
    { en: 'The zombie period.', no: 'Zombie-perioden.' },
    { en: 'The black magic period.', no: 'Den svarte magiperioden.' },
    { en: 'The light years.', no: 'Lysårene.' },
    { en: 'The dark years.', no: 'De mørke årene.' },
    { en: 'The sinful years.', no: 'De syndige år.' },
    { en: 'The sidewind years.', no: 'Årene med sidevind.' },
    { en: 'The atomic years.', no: 'Atomårene.' },
    { en: 'The snowstorm years.', no: 'Snøstormårene.' },
    { en: 'The last cash currency years.', no: 'De siste årene med kontanter.' },
    { en: 'Winged human-mutants years.', no: 'Menneske-mutanter med vingers år.' },
    { en: 'Human gills-mutant years.', no: 'Menneske-mutanter med gjellers år.' },
    { en: 'Third eye human mutant years.', no: 'Menneske-mutanter med tre øyers år.' },
    { en: '3 meter human mutant years.', no: '3 meters menneskenes år.' },
    { en: 'The Samurai visit their future - our present.', no: 'Samuraiene besøker sin fremtid - vår nåtid.' },
    { en: 'The Romans visit their future - our present.', no: 'Romerne besøke deres fremtid - vår nåtid..' },
    { en: 'The Huns visit their future - our present.', no: 'Hunerne besøke deres fremtid - vår nåtid.' },
    { en: 'The longest winter years.', no: 'De lengste vinters år.' },
    { en: 'The longest summer years.', no: 'De lengste somrenes år.' },
    { en: 'The golden MMA years.', no: 'De gylne MMA-årene i Japan.' },
    { en: 'The second industrial revolution.', no: 'Den andre industrielle revolusjon.' },
    { en: 'The gourmet cooking years.', no: 'Gourmetmatårene.' },
    { en: 'The brain hive years.', no: 'Hjernen tueår.' },
    { en: 'The digital sex age.', no: 'Den digitale sexalderen.' },
    { en: 'Social media ban years.', no: 'Sosiale mediers forbudsår.' },
    { en: 'The last years of the saber tooth tigers.', no: 'De siste årene til sabeltanntigeren.' },
    { en: 'The life of Trottir the Just.', no: 'Livet til Trottir Just.' },
    { en: 'The life of Brenda the last star of Hollywood.', no: 'Livet til Brenda den siste stjernen i Hollywood.' },
    { en: 'The hover suitcase period.', no: 'Svevekoffertperioden.' },
    { en: 'The teleporting period.', no: 'Teleportperioden.' },
    { en: 'World hunger.', no: 'Sult i verden.' },
    { en: 'World economic crisis.', no: 'Verdens økonomiske krise.' },
    { en: 'Galaxy Peace 1.', no: 'Galaksefred 1.' },
    { en: 'Galaxy Peace 2.', no: 'Galaksefred 2.' },
    { en: 'Galaxy Peace 3.', no: 'Galaksefred 3.' },
    { en: 'The Reptile Alien years.', no: 'Perioden til de reptilske aliens.' },
    { en: 'The Zeta Alien years.', no: 'Perioden til Zeta aliens.' },
    { en: 'The Nordic Alien years.', no: 'Perioden til de nordiske aliens.' },
    { en: 'Lying becomes impossible.', no: 'Løgn blir umulig.' },
    { en: 'Self-cleaning shoes go on strike.', no: 'Selvrensende sko går til streik.' },
    { en: 'Lab-grown children revolution.', no: 'Lab-vokste barn gjennomfører revolusjon.' },
    { en: 'Clone Wars 1.', no: 'Klonekrigene 1.' },
    { en: 'Clone Wars 2.', no: 'Klonekrigene 2.' },
    { en: 'Aristotle visits the future.', no: 'Aristoteles besøker fremtiden.' },
    { en: 'Copernicus time travels.', no: 'Copernicus tidsreiser.' },
    { en: 'The life of Uncas the Second.', no: 'Livet av Uncas den andre.' },
    { en: 'The EU-US Union years.', no: 'EU-amerikanske Unionsår.' },
    { en: 'The South-American Union years.', no: 'Sør-amerikanske Unionsår.' },
    { en: 'The South-East Asia Union years.', no: 'Sør-Øst Asia Unionsår.' },
    { en: 'Hover City creation. ', no: 'Skapelsen av Svevegy.' },
    { en: 'Sub-Sea City creation.', no: 'Skapelsen av Undervannsby' },
    { en: 'The Colossos re-creation.', no: 'Gjenskapelsen av Colossos.' },
    { en: 'Live cameras attached to all human eyes.', no: 'Live-kameraer festet til alle menneskelige øyne.' },
    { en: 'The Mountain Years.', no: 'Fjellårene.' },
    { en: 'Middle-East Union.', no: 'Midtøstenunionen.' },
    { en: 'Internet War 1.', no: 'Internettkrig 1.' },
    { en: 'Inernet War 2.', no: 'Internettkrig 2.' },
    { en: 'Global News Boycot.', no: 'Global nyhetsboikott.' },
    { en: 'The Pillow Fight War 1.', no: 'Putekrigene 1.' },
    { en: 'The Pillow Fight War 2.', no: 'Putekrigene 2.' },
    { en: 'Anti-violence gene activation.', no: 'Antivold genaktivering.' },
    { en: 'Anti-bullying gene activation.', no: 'Antimobbing genaktivering.' },
    { en: 'The Fourth Empire.', no: 'Det fjerde riket.' },
    { en: 'Gold Crisis.', no: 'Gullkrisen.' },
    { en: 'The life of God.', no: 'Guds liv.' },
    { en: 'The life of Princess Pi.', no: 'Livet til Princess Pi.' },
    { en: 'The empire of the Moon.', no: 'Imperiet av Månen.' },
    { en: 'The empire of the Sun.', no: 'Imperiet av Solen' },
    { en: 'The empire of the Trees.', no: 'Imperiet av Trærne.' },
    { en: 'The empire of the Strawberries.', no: 'Imperiet av Jordbærene.' },
    { en: 'The empire of the Bats.', no: 'Imperiet av Flaggermusene.' },
    { en: 'The empire of the Sunflowers.', no: 'Imperiet av Solsikkene.' },
    { en: 'The empire of the Asteroids.', no: 'Imperiet av Asteroidene.' },
    { en: 'The Hyperloop US creation.', no: 'Hyperloop lages i USA.' },
    { en: 'The Hyperloop EU creation.', no: 'Hyperloop lages i EU.' },
    { en: 'The Hyperloop Asia creation.', no: 'Hyperloop lages i Asia.' },
    { en: 'The US-EU Hyperloop creation.', no: 'USA-EU Hyperloop lages.' },
    { en: 'The Africa Hyperloop creation.', no: 'Hyperloop lages i Afrika.' },
    { en: 'The Insectoid Invasion.', no: 'Insektenes invasjon.' },
    { en: 'Flying cats terrorize birds.', no: 'Flygende katter terroriserer fugler.' },
    {
        en: 'Giant penguins fight polar bears on the North Pole.',
        no: 'Kjempepingviner slåss mot isbjørner på Nordpolen.'
    },
    { en: 'Death Fights reality TV series.', no: 'Dødskampenes reality TV-serier' },
    { en: 'The Hamsters conquer Spain.', no: 'Hamsterne erobrer Spania.' },
    { en: 'The pigs are banned from Animal Farm.', no: 'Grisene blir utestengt fra Animal Farm.' },
    {
        en: 'Workers refuse to eat soma-pills in the Brave New World.',
        no: 'Arbeidere nekter å spise soma-piller i Brave New World.'
    },
    { en: 'Books become the new status symbol.', no: 'Bøker bli det nye statussymbolet.' },
    { en: 'High heels for men re-appear as fashionable.', no: 'Høye hæler for menn er moderne.' },
    { en: 'Squirrels rebel against the lumberjacks.', no: 'Ekorn gjør opprør mot tømmerhoggere.' },
    { en: 'Music is banned in schools worldwide.', no: 'Musikk forbudt i skoler over hele verden.' },
    { en: 'Religion is banned in schools.', no: 'Religion forbudt i skolene.' },
    { en: 'A new world order is introduced.', no: 'En ny verdensorden blir innført.' },
    { en: 'Giant trees used as living skyscrapers.', no: 'Gigantiske trær brukes som levende skyskrapere.' },
    {
        en: 'Unconscious binaural brain beats brainwash humans.',
        no: 'Ubevisste binaural hjernebeats hjernevasker mennesker.'
    },
    {
        en: 'Remote viewing techniques give secret agencies new spying powers.',
        no: 'Fjernseeingsteknikker gir etteretningstjenester nye spionkrefter.'
    },
    { en: 'The years of love.', no: 'Årene med kjærlighet.' },
    { en: 'The years of enlightenment.', no: 'Årene med opplysning.' },
    { en: 'The years of stupidity.', no: 'Årene med dumhet.' },
    { en: 'The years of darkness.', no: 'Årene med mørke.' },
    { en: "The earth's soul entrapment period.", no: 'Jordens sjelefengselsår.' },
    { en: 'Aliens use humans as their energy source.', no: 'Aliens bruker mennesker som energikilde.' },
    { en: 'Energy-producing clothes become the norm.', no: 'Energiproduserende klær er normen.' },
    { en: 'The last snow period on earth.', no: 'Den siste snøens periode på jorden.' },
    { en: 'The dream years.', no: 'Drømmenes år.' },
    { en: 'The last years of the banking cartels.', no: 'De siste årene med bankkarteller.' },
    { en: 'The last years of the drug cartels.', no: 'De siste årene med narkotikakarteller.' },
    { en: "China's reign as the world's strongest economy.", no: 'Kinas regjeringstid som verdens sterkeste økonomi.' },
    {
        en: 'All human brain memory is uploaded to the World Wide Cloud.',
        no: 'All menneskelig hjernehukommelse lastet opp til World Wide Cloud.'
    },
    { en: 'The power ballad period 2.', no: 'Strømballadenes periode 2.' },
    {
        en: 'The period when all mobile phones were banned one day per week.',
        no: 'Perioden når alle mobiltelefoner ble utestengt en dag per uke.'
    },
    {
        en: 'The years where daily meditation was mandatory in business and in schools.',
        no: 'Årene der daglig meditasjon var obligatorisk i næringslivet og i skolen.'
    },
    { en: 'The Arctic is colonized.', no: 'Arktis er kolonisert.' },
    { en: 'The Antarctic is colonized.', no: 'Antarktis er kolonisert.' },
    { en: 'The years of eternal life.', no: 'Årene med evig liv.' },
    { en: 'The years of music celebration.', no: 'Årene med musikkfeiring.' },
    { en: 'The years of pineapple juice.', no: 'Årene med ananas juice.' },
    { en: 'The years of orange juice.', no: 'Årene med appelsinjuice.' },
    { en: 'The years of blood.', no: 'Årene med blod.' },
    { en: 'The years of tears.', no: 'Årene med tårer.' },
    { en: 'The years of sweat.', no: 'Årene med svette.' },
    { en: 'The years of plastic surgery.', no: 'Årene med plastisk kirurgi.' },
    { en: 'The years of hanging tomatoes.', no: 'Årene med hengende tomater.' },
    { en: 'The flying pigs conquer Animal Farm.', no: 'Flygende griser erobrer Animal Farm.' },
    {
        en: '1984 is the secret manual of all presidents.',
        no: '1984 er den hemmeligheten manualen for alle presidenter.'
    },
    { en: 'The years of the sandstorms.', no: 'Årene med sandstormer.' },
    { en: 'The years of super food.', no: 'Årene med supermat.' },
    { en: 'The period of ooooooohhhmmmm.', no: 'Perioden ooooooohhhmmmm.' },
    { en: 'The fanatic diet years.', no: 'Den fanatiske diettens år.' },
    { en: 'The decline of social media.', no: 'Nedgangen av sosiale medier.' },
    { en: 'The rise of social humans.', no: 'Fremveksten av sosiale mennesker.' },
    { en: 'The decline of consumerism.', no: 'Nedgangen i forbruket.' },
    { en: 'The rise of ethical shopping.', no: 'Framveksten av etisk shopping.' },
    { en: 'Time freezes and the world is stuck.', no: 'Tiden stanser og verden er fastlåst.' },
    { en: 'The period of anxiety.', no: 'Perioden med angst.' },
    { en: 'The period of whiskey.', no: 'Perioden med whiskey.' },
    { en: 'The period of saunas and jacuzzis.', no: 'Perioden med sauna og boblebad.' },
    { en: 'The chess years.', no: 'Sjakkårene.' },
    { en: 'The years of Adam looking for Eve.', no: 'Årene med Adam som leter etter Eva.' },
    { en: 'The years of rotten tomatoes.', no: 'Årene med råtne tomater.' },
    { en: 'Our sun dramatically increases in size.', no: 'Solen øker dramatisk i størrelse.' },
    { en: 'The life of the first world president.', no: 'Livet til den første verdenspresidenten.' },
    { en: 'The period when humans do bad things.', no: 'Perioden når mennesker gjør dårlige ting.' },
    { en: 'The period when humans do good things.', no: 'Perioden når mennesker gjør gode ting.' },
    { en: 'The fox does its thing.', no: 'Reven gjør sine ting.' },
    { en: 'Michael Jackson moonwalks - on the moon.', no: 'Michael Jackson tar sin moonwalk - på månen.' },
    {
        en: "The Arab countries re-emerge as the world's innovation region.",
        no: 'De arabiske landene gjenoppstår som verdens innovasjonsregion.'
    },
    { en: 'The period of flying daggers.', no: 'Perioden med flygende dolker.' },
    { en: 'The years of honesty.', no: 'Årene med ærlighet.' },
    { en: 'The years of barefoot-on-the-beach holidays.', no: 'Årene med barfot-på-stranda helligdager.' },
    { en: 'The years of brain training.', no: 'Årene med hjernetrening.' },
    { en: 'The years of accelerated learning.', no: 'Årene med akselerert læring.' },
    { en: 'The years of habit change.', no: 'Årene med vaneendring.' },
    { en: 'The daytime of the night period.', no: 'Dagtid på natten perioden.' },
    { en: 'Aerokickbox fitness wave.', no: 'Aerokickbox treningsbølgen.' },
    { en: 'Monoboard skiing wave.', no: 'Monoboard skibølge.' },
    { en: 'Bell bottom fashion wave.', no: 'Slengbuksenes motebølge.' },
    { en: 'Neon t-shirt fashion wave.', no: 'Neon t-skjorte motebølge.' },
    { en: 'Trance music wave.', no: 'Trance musikkbølge.' },
    { en: 'The minidisc music wave.', no: 'Minidisc musikkbølge.' },
    { en: 'The global epidemic years.', no: 'De globale epidemiårene.' },
    { en: 'The nuclear aftermath years.', no: 'Atomkjølvannets år.' },
    { en: 'The zero currency years.', no: 'Ingen valuta-årene.' },
    { en: 'The life of Happy Pappy.', no: 'Livet til Glad Pappy.' },
    { en: 'The life of Evil Pizza.', no: 'Livet til Ond Pizza.' },
    { en: 'The life of Holy Macaroni.', no: 'Livet til Holy makaroni.' },
    { en: 'The life of Silly Vanilli.', no: 'Livet til Silly Vanilli.' },
    { en: 'The life of Dirty Thirty.', no: 'Livet til Dirty Thirty.' },
    { en: 'The life of Yellow Fever.', no: 'Livet til Gul Feber.' },
    { en: 'The life of Chocolate Fever.', no: 'Livet til Sjokoladefeber.' },
    { en: 'The years of Opposites Attract.', no: 'Årene med Man Høster Som Man Sår.' },
    { en: 'The years of Instant Gratification.', no: 'Årene med umiddelbar tilfredsstillelse.' },
    { en: 'The road to hell is built.', no: 'Veien til helvete er bygget.' },
    { en: 'The stairway to heaven is built.', no: 'Trapp til himmelen er bygget.' },
    { en: 'The years of yoga.', no: 'Årene med yoga.' },
    { en: 'The years of calisthenics.', no: 'Årene med calisthenics.' },
    { en: 'The years of brain-body training.', no: 'Årene med hjerne-kropp trening.' },
    { en: 'The years of mental hygiene.', no: 'Årene med mental hygiene.' },
    { en: 'The idol years.', no: 'Idolenes år.' },
    { en: 'The years of freerunning.', no: 'Årene med friløping.' }
];
