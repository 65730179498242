<template>
  <div class="DatePart">
    <div
      v-if="label"
      class="label">
      {{ label }}
    </div>
    <div class="digits">
      <Digit
        v-for="(digit, i) of value"
        :key="i"
        :placeholder="placeholder"
        :digit="digit"
        :focus="focus === i"
        @click.native="click(i)" />
    </div>
  </div>
</template>

<script>
import Digit from './Digit';

export default {
    components: { Digit },
    props: {
        value: {
            type: String,
            required: true
        },
        focus: {
            type: Number,
            default: -1
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '?'
        }
    },
    methods: {
        click(index) {
            this.$emit('request-focus', index);
        }
    }
};
</script>

<style scoped lang="scss">
.DatePart {
}
.label {
    color: rgba(black, 0.5);
    font-size: 80%;
    margin-bottom: 0.4em;
}
.digits {
    display: flex;
}
.Digit {
    &:not(:first-child) {
        margin-left: 4px;
    }
}
</style>
