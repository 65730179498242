function createLevelOptions({
    numberOfItems,
    includeYear = false,
    includeMonth = false,
    includeDay = false,
    useTimespan = false,
    digitTime = 5,
    extraTime = 10
}) {
    const complexity = ((includeYear ? 4 : 0) + (includeMonth ? 2 : 0) + (includeDay ? 2 : 0)) * (useTimespan ? 2 : 1);
    const itemTime = digitTime * complexity + extraTime;
    return {
        numberOfItems,
        includeYear,
        includeMonth,
        includeDay,
        useTimespan,

        itemMemorizeTimeLimit: itemTime,
        itemQuizTimeLimit: itemTime,
        stars2: itemTime * 0.7,
        stars3: itemTime * 0.5
    };
}

const rawLevelOptions = [
    createLevelOptions({
        // Level 1.1
        numberOfItems: 4,
        includeYear: true,
        extraTime: 10
    }),

    createLevelOptions({
        // Level 1.2
        numberOfItems: 6,
        includeYear: true
    }),

    createLevelOptions({
        // Level 2.1
        numberOfItems: 8,
        includeYear: true,
        includeMonth: true
    }),

    createLevelOptions({
        // Level 2.2
        numberOfItems: 12,
        includeYear: true,
        includeMonth: true
    }),

    createLevelOptions({
        // Level 2.3
        numberOfItems: 16,
        includeYear: true,
        includeMonth: true
    }),

    createLevelOptions({
        // Level 3.1
        numberOfItems: 20,
        includeYear: true,
        includeMonth: true,
        includeDay: true
    }),

    createLevelOptions({
        // Level 3.2
        numberOfItems: 24,
        includeYear: true,
        includeMonth: true,
        includeDay: true
    }),

    createLevelOptions({
        // Level 3.3
        numberOfItems: 28,
        includeYear: true,
        includeMonth: true,
        includeDay: true
    }),

    createLevelOptions({
        // Level 4.1
        numberOfItems: 28,
        includeYear: true,
        useTimespan: true
    }),

    createLevelOptions({
        // Level 4.2
        numberOfItems: 32,
        includeYear: true,
        useTimespan: true
    }),

    createLevelOptions({
        // Level 4.3
        numberOfItems: 36,
        includeYear: true,
        useTimespan: true
    }),

    createLevelOptions({
        // Level 4.4
        numberOfItems: 40,
        includeYear: true,
        useTimespan: true
    })
];

export default function getLevelOptions(levelIndex) {
    return rawLevelOptions[levelIndex];
}
