<template>
  <div class="MemorizeItemInstruction">
    <div
      class="instruction"
      :style="{ color: theme.primaryColor }">
      <span v-if="endDate">
        {{ $t('timeSpanInstruction') }}
      </span>
      <span v-else>
        {{ $t('instruction') }}
      </span>
    </div>
  </div>
</template>

<translations>
  instruction: 'Memorize the time'
  instruction_no: 'Memorer tidspunktet'
  timeSpanInstruction: 'Memorize the time span'
  timeSpanInstruction_no: 'Memorer tidsrommet'
</translations>

<script>
export default {
    props: ['endDate'],
    inject: ['theme']
};
</script>

<style scoped lang="scss">
.MemorizeItemInstruction {
}
.instruction {
    font-size: 130%;
}
</style>
