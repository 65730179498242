import shuffle from 'lodash-es/shuffle';

import QuizItem from '@/modules/games-shared/components/QuizItem';

import Session from './Session';
import Card from './components/Card';
import MemorizeItemInstruction from './components/MemorizeItemInstruction';
import Question from './components/Question';
import DateTest from './components/DateTest';
import MultipleChoiceDateTest from './components/MultipleChoiceDateTest';

import { stringifyTime } from './utils';

const quizMapping = {
    'GameTimemachine-MemorizeItem': {
        component: Card,
        instructionComponent: MemorizeItemInstruction
    },

    'GameTimemachine-QuizItem': {
        component: QuizItem,
        questionComponent: Question,
        generateTestNames(data, { sourceItem }) {
            if (sourceItem.useMultipleChoiceTest) {
                return ['multipleChoiceDateTest'];
            } else {
                return ['dateTest'];
            }
        },
        tests: {
            dateTest: {
                component: DateTest,
                generateTestData(data) {
                    return {
                        fasit: data
                    };
                },
                calculateScore(data, answer) {
                    function equal(a, b) {
                        if (typeof a === 'object' && typeof b === 'object') {
                            return equal(a.year, b.year) && equal(a.month, b.month) && equal(a.day, b.day);
                        }
                        return a === b;
                    }
                    return equal(data.date, answer.date) && equal(data.endDate, answer.endDate) ? 1 : 0;
                }
            },
            multipleChoiceDateTest: {
                component: MultipleChoiceDateTest,
                generateTestData(data, { dataPool }) {
                    const index = dataPool.indexOf(data);

                    const options = [data];
                    for (let i = 1; i < dataPool.length && i < 4; i++) {
                        options.push(dataPool[(i + index) % dataPool.length]);
                    }
                    // const options = [data, { date: {year: '1000'} }, { date: {year: '1001'} }, { date: {year: '1002'} }].map(x => stringifyTime(x))
                    return {
                        fasit: stringifyTime(data),
                        // type: 'MultipleChoice',
                        requiredChoiceCount: 1,
                        options: shuffle(options).map(x => stringifyTime(x))
                    };
                },
                calculateScore(data, answer, test) {
                    return answer === test.data.fasit ? 1 : 0;
                }
            }
        }
    }
};

export default {
    moduleName: 'moduleGameTimemachine',
    Session,
    quizMapping
};
