import store from '@/store';
import { getRandomInt, pickUniqueRandomItemsWithMemory } from '@/modules/memogenius-game/utils';

import events from './data/events';
import periods from './data/periods';

function getRandomStuff(list, count, listName) {
    const state = store.state.moduleGameTimemachine.questionServiceState;
    const usedMap = JSON.parse(JSON.stringify(state.usedMap || {}));

    return pickUniqueRandomItemsWithMemory(list, count, {
        used: usedMap[listName],
        minimumAvailable: count + 30,
        persist(used) {
            usedMap[listName] = used;
            store.dispatch('moduleGameTimemachine/updateQuestionServiceState', { usedMap });
        }
    });
}

function getRandomEvents(count) {
    return getRandomStuff(events, count, 'events');
}

function getRandomPeriods(count) {
    return getRandomStuff(periods, count, 'periods');
}

class QuestionService {
    getItemsFromIndividualOptions(itemsOptions) {
        const language = store.getters['moduleApp/locale'];
        let numberOfRequiredPeriodTexts = 0;
        let numberOfRequiredEventTexts = 0;
        itemsOptions
            .filter(options => !options.text)
            .forEach(({ endDate }) => {
                if (endDate) {
                    numberOfRequiredPeriodTexts++;
                } else {
                    numberOfRequiredEventTexts++;
                }
            });
        const periodTexts = getRandomPeriods(numberOfRequiredPeriodTexts).map(x => x[language]);
        const eventTexts = getRandomEvents(numberOfRequiredEventTexts).map(x => x[language]);

        return itemsOptions.map(({ date, endDate, text }) => {
            const isPeriod = !!endDate;
            return {
                text: text || (isPeriod ? periodTexts.pop() : eventTexts.pop()),
                date: date,
                endDate: endDate
            };
        });
    }

    getItems(numberOfItems, { includeYear, includeMonth, includeDay, useTimespan }) {
        const language = store.getters['moduleApp/locale'];
        let items = [];

        const sourceItems = useTimespan ? getRandomPeriods(numberOfItems) : getRandomEvents(numberOfItems);

        sourceItems.forEach(sourceItem => {
            const item = {
                text: sourceItem[language]
            };
            const date = {};
            if (includeYear) {
                date.year = `${getRandomInt(3000, 6000)}`.padStart(4, '0');
            }
            if (includeMonth) {
                date.month = `${getRandomInt(1, 13)}`.padStart(2, '0');
            }
            if (includeDay) {
                // IMPROVE:, use valid day
                date.day = `${getRandomInt(1, 31)}`.padStart(2, '0');
            }
            item.date = date;

            if (useTimespan) {
                const endDate = {};
                if (includeYear) {
                    const startYear = parseInt(date.year, 10);
                    endDate.year = `${getRandomInt(startYear, startYear + 100)}`.padStart(4, '0');
                }
                if (includeMonth) {
                    endDate.month = `${getRandomInt(1, 13)}`.padStart(2, '0');
                }
                if (includeDay) {
                    // IMPROVE:, use valid day
                    endDate.day = `${getRandomInt(1, 31)}`.padStart(2, '0');
                }

                item.endDate = endDate;
            }
            items.push(item);
        });

        return items;
    }
}

const questionService = new QuestionService();
export default questionService;
