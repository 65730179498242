<template>
  <div class="Card">
    <div class="spacerTop" />
    <div class="card">
      <div class="date">
        {{ { date, endDate } | stringifyTime }}
      </div>
      <div class="divider" />
      <div class="text">
        {{ text }}
      </div>
    </div>
    <div class="spacerBottom" />
  </div>
</template>

<script>
import { stringifyTime } from '../utils';

export default {
    filters: {
        stringifyTime: function (value) {
            return stringifyTime(value);
        }
    },
    props: ['text', 'date', 'endDate'],
    computed: {}
};
</script>

<style scoped lang="scss">
.Card {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 2em;
}
.card {
    padding: 2em 1em;
    background-color: white; //rgba(white, 0.8);
    color: black;
    border-radius: 0.5em;
    // width: 20em;
    align-self: stretch;

    box-shadow: 0 1em 1em rgba(black, 0.2);
}

.spacerTop {
    flex: 0.3;
}
.spacerBottom {
    flex: 1;
}
.date {
    font-size: 140%;
    text-align: center;
    font-weight: 600;
    color: rgba(black, 0.8);
    letter-spacing: 0.1em;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
.text {
    line-height: 1.5em;
    color: mix(black, white, 80%);
    font-size: 120%;
}
</style>
