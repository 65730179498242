import shuffle from 'lodash-es/shuffle';

import generateSessionReport from '@/modules/memogenius-game/generateSessionReport';

import questionService from './question-service';
import { stringifyTime } from './utils';
import getLevelOptions from './get-level-options';

export default class Session {
    constructor(options) {
        if (options.levelIndex !== undefined) {
            this.levelIndex = options.levelIndex;
            this.options = getLevelOptions(this.levelIndex);
        } else {
            this.options = options;
        }
    }
    generateMemorize() {
        const {
            numberOfItems,
            includeYear,
            includeMonth,
            includeDay,
            useTimespan,
            itemMemorizeTimeLimit,
            items = null
        } = this.options;
        const memorizeTimeLimit = itemMemorizeTimeLimit * numberOfItems;

        const events = items
            ? questionService.getItemsFromIndividualOptions(items)
            : questionService.getItems(numberOfItems, {
                  includeYear,
                  includeMonth,
                  includeDay,
                  useTimespan
              });

        return {
            items: events.map(xx => {
                return {
                    memorizeItemType: 'GameTimemachine-MemorizeItem',
                    timeLimit: itemMemorizeTimeLimit,
                    data: xx
                };
            }),
            timeLimit: memorizeTimeLimit,
            timeoutPerItem: true
        };
    }
    reportMemorize({ items, timeUsed }) {
        this.memorizeReport = { items, timeUsed };
    }
    generateQuiz() {
        const { numberOfItems, itemQuizTimeLimit } = this.options;
        const quizTimeLimit = itemQuizTimeLimit * numberOfItems;

        let useMultipleChoiceTestArr = [];
        for (let i = 0; i < numberOfItems; i++) {
            useMultipleChoiceTestArr.push(i < 0.25 * numberOfItems);
        }
        useMultipleChoiceTestArr = shuffle(useMultipleChoiceTestArr);

        const items = this.memorizeReport ? this.memorizeReport.items : this.generateMemorize().items;
        const sourceItems = items.map((item, i) => {
            return {
                quizItemType: 'GameTimemachine-QuizItem',
                data: item.data,
                useMultipleChoiceTest: useMultipleChoiceTestArr[i],
                memorizeTime: item.timeUsed
            };
        });

        return {
            sourceItems,
            items: [],
            timeLimit: quizTimeLimit,
            lives: 3
        };
    }
    reportQuiz({ items, timeUsed, score, sourceItems }) {
        const { stars2, stars3, numberOfItems } = this.options;
        const memorizeTime = this.memorizeReport && this.memorizeReport.timeUsed;
        let stars = 0;
        // NOTE: should not give stars if score < 1. But score wrongely reported, so ignored
        if (memorizeTime) {
            if (memorizeTime < stars3 * numberOfItems * 1000) {
                stars = 3;
            } else if (memorizeTime < stars2 * numberOfItems * 1000) {
                stars = 2;
            } else {
                stars = 1;
            }
        }

        this.quizReport = { items, timeUsed, score, sourceItems, stars };
    }
    generateReport() {
        return generateSessionReport({
            memorizeReport: this.memorizeReport,
            quizReport: this.quizReport,
            initial(item, sourceItem) {
                return { type: 'Text', value: sourceItem.data.text };
            },
            attempt(test) {
                return {
                    value:
                        test.submittedAnswer && typeof test.submittedAnswer === 'object'
                            ? stringifyTime(test.submittedAnswer)
                            : test.submittedAnswer,
                    score: test.score
                };
            }
        });
    }

    generateSessionOptionsForRetry() {
        return Object.assign({}, this.options, {});
    }
}
