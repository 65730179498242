<template>
  <div class="Date">
    <DatePart
      :placeholder="$t('yearAbbr')"
      :value="year"
      :label="showLabels ? $t('year') : ''"
      :focus="focus.year"
      @request-change="value => $emit('request-change', 'year', value)"
      @request-focus="index => $emit('request-focus', 'year', index)" />
    <template v-if="month">
      <div class="separator">
        {{ separator }}
      </div>
      <DatePart
        :placeholder="$t('monthAbbr')"
        :value="month"
        :label="showLabels ? $t('month') : ''"
        :focus="focus.month"
        @request-focus="index => $emit('request-focus', 'month', index)" />
    </template>
    <template v-if="day">
      <div class="separator">
        {{ separator }}
      </div>
      <DatePart
        :placeholder="$t('dayAbbr')"
        :value="day"
        :label="showLabels ? $t('day') : ''"
        :focus="focus.day"
        @request-focus="index => $emit('request-focus', 'day', index)" />
    </template>
  </div>
</template>

<translations>
  year: 'Year'
  year_no: 'År'
  month: 'Month'
  month_no: 'Måned'
  day: 'Day'
  day_no: 'Dag'
  yearAbbr: 'Y'
  yearAbbr_no: 'Å'
  monthAbbr: 'M'
  monthAbbr_no: 'M'
  dayAbbr: 'D'
  dayAbbr_no: 'D'
</translations>

<script>
import DatePart from './DatePart';

export default {
    components: { DatePart },
    props: {
        year: {
            type: String,
            required: true
        },
        month: {
            type: String
        },
        day: {
            type: String
        },
        focus: {
            type: Object,
            default: function () {
                return {};
            }
        },
        showLabels: {
            type: Boolean,
            defalt: false
        }
    },
    computed: {
        separator() {
            return this.compact ? '.' : '/';
        }
    }
};
</script>

<style scoped lang="scss">
.Date {
    display: flex;
    align-items: flex-end;
}
.separator {
    height: 2.2em;
    width: 1.4em;
    color: rgba(black, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
