export default [
    { en: 'The first UFO captured by NASA', no: 'Første UFO fanget av NASA' },
    { en: 'Alien telepathy techniques adopted by the CIA', no: 'Alien telepati-teknikker brukt av CIA' },
    {
        en: 'Area 51 is created as the alien headquarter for the US, Russia and China.',
        no: 'Area 51 opprettet som hovedkontor for aliens i USA, Russland og Kina.'
    },
    {
        en: 'The alien war ends in a massive explosion killing the dinasours.',
        no: 'Alien-krigen ender i en massiv eksplosjon som drepte dinasourene.'
    },
    { en: 'Anti-gravity technology is discovered.', no: 'Anti-gravitasjonsteknologi oppdaget.' },
    {
        en: 'Out of body experience holiday operators form a labor union.',
        no: 'Ut-av-kroppen-opplevelsesferieselskaper danner en fagforening.'
    },
    { en: 'Meat is grown in domestic gardens.', no: 'Kjøtt blir dyrket i private hager til hus og leilighter.' },
    {
        en: 'The price of oxygen bottles is twice as expensive water bottles.',
        no: 'Prisen på oksygenflasker er dobbelt så dyr som for vannflasker.'
    },
    { en: 'World War 3 starts.', no: '3. verdenskrig starter.' },
    {
        en: 'A new breakaway amazone nation is created in Nigeria.',
        no: 'En ny utbryter amazone-nasjon etablert i Nigeria.'
    },
    {
        en: 'The personal drone law allows people to travel by air on top of existing streets.',
        no: 'Den personlige droneloven tillater folk å fly over eksisterende gater.'
    },
    {
        en: 'Solar energy becomes the primary global energy source.',
        no: 'Solenergi blir den primære globale energikilden.'
    },
    { en: 'New dimensions are discovered.', no: 'Nye dimensjoner blir oppdaget.' },
    { en: 'Time travel causes memory problems.', no: 'Tidsreiser forårsaker problemer med hukommelsen.' },
    {
        en: 'Food pills become the primary nutrition source in the world.',
        no: 'Matpiller bli den primære ernæringskilden i verden.'
    },
    { en: 'Sex goes digital.', no: 'Sex blir digital allemannseie.' },
    {
        en: 'Renegade robots call Arnold Schwarzenegger a pussy.',
        no: 'Opprørske roboter kaller Arnold Schwarzenegger en pyse.'
    },
    {
        en: 'Bio-engineering double the average human height.',
        no: 'Genmanipulering dobler den gjennomsnittlige høyden på mennesker.'
    },
    {
        en: 'Elvis Presley is brought back to life and starts to rap.',
        no: 'Elvis Presley blir brakt tilbake til live igjen og begynner å rappe.'
    },
    { en: 'World War 3 ends', no: '3. verdenskrig ender.' },
    { en: 'Underground alien civilizations are discovered.', no: 'Underjordiske aliensivilisasjoner blir oppdaget.' },
    {
        en: 'Time travel technology bugs cause irreversible dimension travels.',
        no: 'Tidsreiseteknologi har bugs som fører til irreversible dimensjonsreiser.'
    },
    {
        en: 'Atlantis tops the no. 1 most popular time travel destination.',
        no: 'Atlantis topper som det mest populære tidsreisemålet.'
    },
    {
        en: 'People return from parallell dimensions with their other selves.',
        no: "Folk kommer tilbake fra parallelle dimensjoner med sine andre jeg'er."
    },
    { en: 'All illness is cured.', no: 'All sykdom blir kurert.' },
    {
        en: 'The first man to live 500 years commits suicide out of boredom.',
        no: 'Det første mennesket som lever i 500 år begår selvmord av kjedsomhet.'
    },
    {
        en: 'The violence gene is genetically removed in all new-born babies.',
        no: 'Voldsgenet er genetisk fjernet i alle nyfødte babyer.'
    },
    { en: 'Humans colonize the moon.', no: 'Mennesker koloniserer månen.' },
    { en: 'Humans colonize Mars.', no: 'Mennesker koloniserer Mars.' },
    {
        en: 'The humanoid colony on the dark side of the moon is discovered.',
        no: 'Humanoid koloni på den mørke siden av månen blir oppdaget.'
    },
    {
        en: 'Time travel providers go real time by enabling a live regret behavior switch.',
        no: 'Tidsreiseleverandører innfører en live angre-bryter.'
    },
    {
        en: 'Nano-tech developed microbes develop complex language skills.',
        no: 'Nano-techutviklete mikrober utvikler avanserte språkferdigheter.'
    },
    {
        en: 'Santa Clause moves to the South Pole, as the North Pole becomes inhabitable during summer.',
        no: 'Julenissen flytter til Sydpolen, ettersom Nordpolen blir beboelig i løpet av sommeren.'
    },
    {
        en: 'The Scandinavian countries form their own union within the EU.',
        no: 'De skandinaviske landene danne sin egen union i EU.'
    },
    { en: 'Anti-vacuum space fish are discovered.', no: 'Anti-vakuums-romfisk blir oppdaget.' },
    { en: 'Love sold in a bottle for the first time.', no: 'Kjærlighet selges på flaske for første gang.' },
    {
        en: 'All children in Memoland speak 5 languages fluently by the age of 10.',
        no: 'Alle barn i Memoland snakker fem språk flytende i en alder av 10.'
    },
    {
        en: 'The world government imposes a global 1-child policy.',
        no: 'Verdensregjeringen innfører en global ett-barns-politikk.'
    },
    {
        en: 'Computer programming is accelerated by brain chips enabling direct thought-to-code creation.',
        no: 'Programmering blir hurtigere med hjernechips som muliggjør direkte overføring fra tanke-til-kode.'
    },
    { en: 'Artificial Intelligence takes over the NSA and CIA.', no: 'Kunstig intelligens overtar NSA og CIA.' },
    {
        en: 'Holograms become multi-sensory, enabling touch, taste and smell abilities.',
        no: 'Hologrammer bli multisensoriske og muliggjør berøring, smak og lukt.'
    },
    {
        en: 'Aging reversal tech makes everyone look the same age.',
        no: 'Teknologi for reversering av aldring gjør at alle ser like gamle ut.'
    },
    { en: 'Venus is colonized by humans.', no: 'Venus blir kolonisert av mennesker.' },
    {
        en: 'Humor doctors have normal doctors as their largest patient group.',
        no: 'Humorleger får normale leger som sin største pasientgruppe.'
    },
    {
        en: 'The time travel industry forms the history rewriting society.',
        no: 'Tidsreiselivsbransjen danner foreningen for historieomskrivning.'
    },
    {
        en: 'Nano-technology enable humans to breathe underwater.',
        no: 'Nano-teknologi gjør det mulig for mennesker å puste under vann.'
    },
    { en: 'Inter-robot marriages are allowed.', no: 'Inter-robot ekteskap blir tillatt.' },
    { en: 'Artificial Intellingence goes emotional.', no: 'Kunstig intellingens får følelser.' },
    {
        en: 'All school children learn theory at home and do real-life projects in class.',
        no: 'Alle skolebarn lære teorien hjemme og gjøre prosjekter i klassen.'
    },
    {
        en: 'Super-skinny jeans for men are abolished by law.',
        no: 'Super-skinny jeans for menn blir avskaffet ved lov.'
    },
    {
        en: 'Hover skateboards function on top of any surface.',
        no: 'Svevende skateboards fungerer over  alle overflater.'
    },
    {
        en: 'Mutants with 6 fingers and 3 eyes become the new beauty standard.',
        no: 'Mutanter med seks fingre og tre øyne bli den nye skjønnhetsstandarden.'
    },
    {
        en: 'Mind-over-matter technology enables 3D printing by thought.',
        no: 'Tanketeknologi muliggjør 3D-printing ved å tenke. '
    },
    {
        en: "Time travel extremists reach the end of time, only to realize it's the beginning.",
        no: 'Tidsreiseekstremister ankommer tidens slutt, bare for å innse at det er begynnelsen.'
    },
    {
        en: 'Time travel conservationists fight for time span and time restriction policies.',
        no: 'Tidsreisenaturvernere kjemper for politsk regelverk for tidsrom og tidsbegrensninger.'
    },
    { en: 'Time travel creates new time dimensions.', no: 'Tidsreiser skaper nye tidsdimensjoner.' },
    {
        en: 'Parallel dimension travels open up new market opportunities for Hello Kitty.',
        no: 'Parallelle dimensjonsreiser åpne nye markedsmuligheter for Hello Kitty.'
    },
    {
        en: 'Nuclear space bombs cause alien earth invasion.',
        no: 'Atombomber i verdensrommet fører til en jordinvasjon av aliens.'
    },
    { en: 'Space elevators are invented.', no: 'En heis til verdensrommet blir laget.' },
    {
        en: 'Time machines travel back in time for self-maintenance.',
        no: 'Tidsmaskiner reiser tilbake i tid for å vedlikeholde seg selv.'
    },
    {
        en: 'The person behind the Back To The Future as default time machine entertainment is assasinated.',
        no: 'Personen som innførte filmene Tilbake Til Fremtiden som standard tidsreiseunderholdning blir myrdet.'
    },
    { en: 'Self-doctoring goes mainstream.', no: 'Folk blir sine egne leger.' },
    { en: 'Brain chips cause mental disorders.', no: 'Hjernechips forårsaker psykiske lidelser.' },
    { en: 'Sneezing is abolished.', no: 'Nysing blir avskaffet.' },
    { en: 'Alien virus causes zombie like sickness.', no: 'Alien-virus forårsaker zombie-liknende sykdom.' },
    {
        en: 'The Mars colonists deem the term Marsian as racist and punishable by law.',
        no: 'Mars-kolonister etablerer begrepet Marsboer som rasistisk og straffbart.'
    },
    {
        en: 'Money is abolished in a series of eco-villages world-wide.',
        no: 'Penger blir avskaffet i en serie av øko-landsbyer over hele verden.'
    },
    { en: 'First DNA-dating website launched.', no: 'Første nettside for DNA-dating lansert.' },
    { en: 'Intelligence pills invented.', no: 'Etterretningspiller oppfunnet.' },
    {
        en: 'Bruce Lee is brought back to life and becomes a tap dancer.',
        no: 'Bruce Lee blir brakt tilbake til live og blir en tapdanser.'
    },
    { en: 'The first world president is elected.', no: 'Den første verdenspresidenten velges.' },
    { en: 'Self-cleaning teeth are invented.', no: 'Selvrensende tenner oppfunnet.' },
    {
        en: 'Contact lenses with binocular functionality invented.',
        no: 'Kontaktlinser med kikkertfunksjonalitet oppfunnet.'
    },
    { en: 'Language pills invented.', no: 'Språkpiller oppfunnet.' },
    { en: 'The first Mars War starts.', no: 'Den første Mars-krigen starter.' },
    { en: 'Second generation time machines create time loops.', no: 'Andregenerasjons tidsmaskiner lager tidsloops.' },
    { en: 'Zeta aliens revealed as double agents.', no: 'Zeta aliens avslørt som dobbeltagenter.' },
    {
        en: 'Space hitchiking organisation trademarks the number 42.',
        no: 'Organisasjonen for haiking i verdensrommet får varemerkerettigheter for nummer 42.'
    },
    {
        en: 'Mutated sea horses morph into terrorizing dragons.',
        no: 'Muterte sjøhester forvandler seg til terroriserende drager.'
    },
    {
        en: 'Space fishing provides food during space travels.',
        no: 'Fisking i verdensrommet gir mat på verdensromreiser.'
    },
    {
        en: 'Elon Musk elected as the first president on Mars.',
        no: 'Elon Musk valgt som den første presidenten på Mars.'
    },
    {
        en: 'Google cars AI robots strike due to long working hours.',
        no: 'Google-bilenes roboter streiker på grunn av lang arbeidstid.'
    },
    { en: 'Superman shows himself for the first time.', no: 'Superman viser seg for første gang.' },
    { en: 'Batman insists on wearing white outfits.', no: 'Batman insisterer på å få lov til å bruke hvite drakter.' },
    {
        en: 'Buddha, Jesus, Mohammad and Krishna are reborn for a stand-up comedy tour.',
        no: 'Buddha, Jesus, Mohammad og Krishna blir gjenfødt for å holde en felles stand-up komedieturne.'
    },
    { en: 'Subsea cross-continental trains are launched.', no: 'Undersjøiske kryss-kontinentale tog blir lansert.' },
    { en: 'Photographic memory is invented.', no: 'Fotografisk hukommelse blir oppfunnet.' },
    { en: 'The holy grail is found.', no: 'Den hellige gral blir funnet.' },
    { en: 'Giant Paracas-similar skulls are found.', no: 'Enorme Paracas-lignende hodeskaller blir funnet.' },
    { en: 'Mobile teleportation device teleports itself.', no: 'Mobil teleportenhet teleporterer seg selv.' },
    {
        en: "Steve Jobs is brought back to life to become king of the world's first floating city nation.",
        no: 'Steve Jobs blir brakt tilbake til live for å bli konge av verdens første flytende bynasjon.'
    },
    {
        en: 'Brain training and accelerated learning become mandatary school subjects.',
        no: 'Hjernetrening og akselerert læring blir obligatoriske skolefag.'
    },
    { en: 'Time travel gives new meaning to part-time work.', no: 'Tidsreiser gir ny mening til deltidsarbeid.' },
    {
        en: 'Time travel gives new meaning to living in different time zones.',
        no: 'Tidsreiser gir ny mening til det å leve i forskjellige tidssoner.'
    },
    { en: 'Longevity researchers achieve the goals of eternal life.', no: 'Levetidsforskere når målet om evig liv.' },
    {
        en: 'Kitchens with self-sustaining micro-gardens launched.',
        no: 'Kjøkken med selvforsørgende mikrohager lansert.'
    },
    { en: 'Self-cleaning houses invented.', no: 'Selvrensende hus oppfunnet.' },
    { en: 'The first Mars War ends.', no: 'Den første Mars-krigen er slutt.' },
    { en: 'Gene responsible for sense of humor discovered.', no: 'Genet ansvarlig for humor oppdaget.' },
    {
        en: 'Telekinesis - the ability to move matter with mind - discovered.',
        no: 'Telekinese - evnen til å flytte materie med tankene - oppdaget.'
    },
    { en: 'The global four workdays per week begins.', no: 'De globale fire arbeidsdager per uke begynner.' },
    { en: 'The brain-flash-light is invented.', no: 'Hjerne-flash-lys blir oppfunnet.' },
    { en: 'Worldwide religion peace treaty signed.', no: 'Verdensomspennende fredsavtale mellom religioner signert.' },
    {
        en: 'Trash left in the streets due to world-wide strikes.',
        no: 'Søppel overalt i gatene på grunn av verdensomfattende streik.'
    },
    {
        en: 'Hospitals worldwide closed by epidemic flu.',
        no: 'Sykehus over hele verden stengt etter influensaepidemi '
    },
    { en: 'Parrot can speak 5 languages.', no: 'Papegøye kan snakke fem språk.' },
    { en: 'India and Pakistan reunite as one country.', no: 'India og Pakistan gjenforent som ett land.' },
    { en: 'New weapon of mass destruction created.', no: 'Nytt masseødeleggelsesvåpen laget.' },
    { en: 'Policeman found dead on highway', no: 'Politimann funnet død på motorveien.' },
    { en: 'Weapon of mass creation invented.', no: 'Masseskapelsesvåpen oppfunnet.' },
    { en: 'The pope visits Egypt.', no: 'Paven besøker Egypt.' },
    { en: 'Man becomes pregnant.', no: 'Mann blir gravid.' },
    { en: 'Hollywood is moved to Africa.', no: 'Hollywood flyttet til Afrika.' },
    { en: 'New eye-opening color discovered.', no: "Ny 'øyevekkende' farge oppdaget." },
    { en: 'Shoes run alone ... or in pairs.', no: 'Sko løper alene ... eller i par.' },
    { en: 'Flying highways created.', no: 'Flymotorveier opprettet.' },
    { en: 'Backpacker arrested', no: 'Backpacker arrestert' },
    { en: 'First baby-buggy race held', no: 'Første barnevognrace avholdt.' },
    { en: 'Vulcanic eruption creates a new continent.', no: 'Vulkanutbrudd skaper et nytt kontinent.' },
    { en: 'First square with 5 corners invented.', no: 'Første firkant med fem hjørner oppfunnet.' },
    { en: 'Fashion brand made of organic food launched.', no: 'Motemerke laget av økologisk mat lansert.' },
    { en: 'Tornado travels all around the world.', no: 'Tornado reiser verden rundt.' },
    {
        en: 'Mind reading becomes the most popular hobby on public transport.',
        no: 'Tankelesing blir den mest populære hobbyen på offentlig transport.'
    },
    { en: 'Capoeira-performing grandmothers tour the world.', no: 'Capoeira-utførerende bestemødre turnerer verden.' },
    {
        en: 'Children are brought up in communities instead of families.',
        no: 'Barn oppdras i fellesskap i småsamfun i stedet for i familier.'
    },
    { en: 'New island is found in the pacific ocean.', no: 'Ny øy funnet i Stillehavet.' },
    { en: 'Mandarine is declared as world language number 1.', no: 'Mandarin erklært som verdensspråk nummer 1.' },
    { en: 'Purple trees invade the UN.', no: 'Lilla trær invaderer FN.' },
    { en: 'Adaptable high-heel shoes launched.', no: 'Tilpasningsdyktige høyhælte sko lansert.' },
    { en: 'Living Pharaoh found in pyramid', no: 'Levende farao funnet i pyramide.' },
    { en: 'Talking trees discovered in the Amazone jungle.', no: 'Snakkende trær oppdaget i Amazonas. .' },
    { en: 'Flying monkeys discovered in Malaysia.', no: 'Flygende aper oppdaget i Malaysia.' },
    {
        en: 'Mobile phone brain implants become popular among teenagers.',
        no: 'Mobiltelefon som hjerneimplantater populært blant tenåringer.'
    },
    { en: 'First fatal space shuttle explosion on Mars.', no: 'Første dødlige romfergeeksplosjon på Mars.' },
    {
        en: 'Samsung and Sony merge to dominate the future of tech.',
        no: 'Samsung og Sony fusjonerer for å dominere fremtiden innen tech.'
    },
    { en: 'Earthquake dislocates continents.', no: 'Jordskjelv skiller kontinenter.' },
    {
        en: 'Interactive comics and games replace books in schools.',
        no: 'Interaktive tegneserier og spill erstatter bøker i skolen.'
    },
    {
        en: 'Big Bang simulation creates a parallel universe.',
        no: 'Simulering av Big Bang skaper et parallelt univers.'
    },
    { en: 'Tsunami uncovers Atlantis.', no: 'Tsunami bringer frem Atlantis.' },
    {
        en: 'Saber tooth tigers appear from giant hole in Siberia.',
        no: 'Sabeltanntiger kommer opp fra gigantisk hull i Sibir.'
    },
    { en: 'Skyscraper has more than 7 000 floors.', no: 'Skyskraper har mer enn 7 000 etasjer.' },
    { en: 'Magic carpet escapes from Disneyland.', no: 'Magisk flygende teppe rømmer fra Disneyland.' },
    {
        en: 'Shamans outcompete doctors in treating the parallel universe disease.',
        no: 'Sjamaner utkonkurrerer leger i behandling av sykdommer fra parallelle univers.'
    },
    {
        en: 'Sherlock Holmes brought back to life and becomes obsessed with his own TV-series.',
        no: 'Sherlock Holmes brakt tilbake til live og blir besatt av sin egen TV-serie.'
    },
    { en: 'Talking zoo animals plan revolution.', no: 'Snakkende dyr i dyrehage planlegger revolusjon.' },
    { en: 'Basketball rules change to include 4 baskets.', no: 'Basketballreglene endres til å omfatte fire kurver.' },
    { en: 'Flowers need wine for growing.', no: 'Blomster trenger vin for å vokse.' },
    {
        en: 'Facebook replaced by Bodybook as the dominant social media.',
        no: 'Facebook erstattet av Bodybook som det største sosiale mediet.'
    },
    { en: 'Chairs go out of fashion.', no: 'Stoler gå av moten.' },
    { en: 'Rollercoaster ride creates world peace.', no: 'Berg-og-dalbane skaper verdensfred.' },
    { en: 'Party church established.', no: 'Festkirken etablert.' },
    { en: 'All cars use water as fuel.', no: 'Alle biler bruker vann som drivstoff.' },
    {
        en: 'Albert Einstein is brought back to life and starts to explore astrology.',
        no: 'Albert Einstein brakt tilbake til live og begynner å utforske astrologi.'
    },
    { en: 'Fast food chains abolished in most countries.', no: 'Fast food-kjeder avskaffet i de fleste land.' },
    {
        en: 'Talking books tell different stories than the texts they contain.',
        no: 'Snakkende bøker forteller forskjellige historier enn tekstene de inneholder.'
    },
    { en: 'Men start laying eggs all over the world.', no: 'Menn begynner å legge egg over hele verden.' },
    { en: 'Comet destroys London.', no: 'Komet ødelegger London.' },
    { en: 'Woman elected as the president of the US.', no: 'Kvinne valgt til president i USA.' },
    { en: 'First human is cloned.', no: 'Første menneske er klonet.' },
    {
        en: 'The new waterbomb used by terrorists in swimming camps.',
        no: 'Den nye vannbomben brukt av terrorister i svømmeleire.'
    },
    { en: 'Latin re-emerges as the number one world language.', no: 'Latin gjenoppstår som verdensspråk nummer én.' },
    { en: 'All universities changed to fun-camps.', no: 'Alle universiteter endret til moro-leire' },
    { en: 'Crying prescribed as anti-headache cures.', no: 'Gråt foreskrevet som anti-hodepinemedisin.' },
    { en: 'Bungee-jumping compulsory in schools.', no: 'Strikkhopp obligatorisk i skolen.' },
    {
        en: 'All world citizens are tagged with serial codes and for your own protection labels.',
        no: 'Alle verdensborgere er merket med seriekoder og for din egen beskyttelse-etiketter.'
    },
    { en: 'Chickens replace cows as milk producers.', no: 'Kyllinger erstatter kuer som melkeprodusenter.' },
    {
        en: "12-year old genius becomes the world's first president.",
        no: '12-årig geni blir verdens første president.'
    },
    { en: 'All promises are suddenly kept.', no: 'Alle løfter blir plutselig holdt.' },
    { en: 'Witches use vacuum cleaners to fly.', no: 'Hekser bruker støvsugere til å fly.' },
    {
        en: 'Male hair loss is cured and long hair for men becomes popular.',
        no: 'Mannlig hårtap kurert og langt hår for menn blir populært.'
    },
    {
        en: 'Melting icebergs in Antarctica reveal alien underground bases.',
        no: 'Smeltende isfjell i Antarktis avslører underjordiske alien baser.'
    },
    {
        en: 'France runs out of wine and declares national emergency.',
        no: 'Frankrike går tom for vin og erklærer nasjonal krisesituasjon.'
    },
    { en: 'Men fully understand what women really want.', no: 'Menn forstår fullt ut hva kvinner egentlig vil ha.' },
    {
        en: 'The genie in a bottle is found tortured by a Christina Aguilera song.',
        no: 'Ånden i lampen blir funnet - ble torturert av en Christina Aguilera sang.'
    },
    {
        en: 'Counter-clockwise watches become popular.',
        no: 'Klokker med visere med motsatt klokkeretning blir populære.'
    },
    { en: 'Dolphins win the Nobel Peace prize.', no: 'Delfiner vinner Nobels fredspris.' },
    { en: 'Free wifi for everyone world wide declared by law.', no: 'Gratis wifi for alle i verden erklært ved lov.' },
    { en: 'Mutant bananas replace surfboards.', no: 'Mutante bananer erstatter surfebrett.' },
    { en: 'Casinos run out of money.', no: 'Kasinoer går tomme for penger.' },
    {
        en: 'Celebrities and politicians turn out to be aliens.',
        no: 'Kjendiser og politikere viser seg å være romvesener.'
    },
    { en: 'The day turns into 48 hours.', no: 'Dagen blir til 48 timer.' },
    {
        en: 'World oceans lose their water and humans move underground.',
        no: 'Verdenshav mister vann og mennesker flytter under bakken.'
    },
    { en: 'Birds hypnotize people with their songs.', no: 'Fugler hypnotiserer folk med sine sanger.' },
    { en: '6-months old baby singer amazes the world.', no: '6-måneder gammel babysanger forundrer verden.' },
    {
        en: 'Crocodiles create their own nation in northern Australia.',
        no: 'Krokodiller lager sin egen nasjon i det nordlige Australia.'
    },
    { en: 'Cats use cutlery to eat their fish.', no: 'Katter bruker bestikk til å spise sin fisk.' },
    { en: 'Doctors find a cure for insomnia.', no: 'Legene finner en kur mot søvnløshet.' },
    { en: 'Dolphin becomes a famous talk show host.', no: 'Delfin blir berømt talkshow-vert.' },
    { en: 'The moon and the sun switch places.', no: 'Månen og solen bytter plass.' },
    { en: 'Treehouses become the houses with the most prestige.', no: 'Trehus blir husene med mest prestisje.' },
    {
        en: 'The boyband killer kills his fans and the music genre.',
        no: 'Boybandmorderen dreper sine fans og sin egen musikksjanger.'
    },
    { en: 'Untraceable poison invented.', no: 'Usporbar gift oppfunnet.' },
    { en: 'Russia forbids Vodka.', no: 'Russland forbyr Vodka.' },
    { en: 'Sex is forbidden unless for reproduction.', no: 'Sex blir forbudt med mindre det gjelder reproduksjon.' },
    { en: 'Africa becomes the richest continent in the world.', no: 'Afrika blir det rikeste kontinentet i verden.' },
    { en: 'Whale saves a sinking ship.', no: 'Hval redder et synkende skip.' },
    {
        en: 'The existence of fairies is proven by scienctific studies.',
        no: 'Eksistensen av feer bevises av vitenskapelige studier.'
    },
    {
        en: 'The first human to grow wings is shot by accident.',
        no: 'Det første mennesket med vinger blir skutt ved et uhell.'
    },
    { en: 'A new calender and time table is introduced.', no: 'En ny kalender og tidstabell blir innført.' },
    { en: 'A new counting system is introduced.', no: 'Et nytt tellesystemet blir innført.' },
    {
        en: 'The global Terra-currency replaces all national currencies.',
        no: 'Den globale Terra-valutaen erstatter alle nasjonale valutaer.'
    },
    { en: "The Valentine's Day is moved to September.", no: 'Valentinsdagen flyttet til september.' },
    { en: 'The US is split into 52 countries.', no: 'USA blir delt inn i 52 land.' },
    { en: 'All cartoon stars become real.', no: 'Alle tegneseriehelter blir ekte.' },
    {
        en: 'Washing machines with auto-ironing feature invented.',
        no: 'Vaskemaskiner med auto-stryke funksjonen oppfunnet.'
    },
    { en: 'Talking cars go mainstream.', no: 'Snakkende biler blir allemannseie.' },
    { en: 'Big thunderstorms only attack at night.', no: 'Tordenvær kun om natten.' },
    { en: 'The cure for cancer is found.', no: 'Kur for kreft blir oppfunnet.' },
    {
        en: 'Genghis Khan is brought back to life and becomes a pilot.',
        no: 'Genghis Khan er brakt tilbake til live og blir en pilot.'
    },
    {
        en: 'The laugh-until-you-die method becomes legal for terminally ill people.',
        no: 'Metoden le-til-du-dør blir lovlig for dødssyke mennesker.'
    },
    { en: 'The first car race on the moon is organized.', no: 'Det første billøpet på månen blir organisert.' },
    { en: 'The genius gene is discovered.', no: 'Det geniale genet blir oppdaget.' },
    { en: 'The average life span passes 200 years.', no: 'Gjennomsnittlig levetid passerer 200 år.' },
    {
        en: 'The White House graffiti mystery is solved - it was the president.',
        no: 'Graffitimysteriet i Det hvite hus løst - det var presidenten.'
    },
    {
        en: 'The first world Queen organizes a world party in Rio de Janeiro.',
        no: 'Den første verdensdronningen organiserer verdensfest i Rio de Janeiro.'
    },
    { en: 'Mutant insects used as transportation tools.', no: 'Mutante insekter brukes som transportverktøy.' },
    { en: 'The lion king is born.', no: 'Løvekongen blir født.' },
    {
        en: 'Butterflies rebel agains the butterfly-effect theory.',
        no: 'Sommerfugler gjør opprør mot sommerfugl-effekt-teorien.'
    },
    {
        en: 'Meteors and asteroids play hide and seek around the international space station.',
        no: 'Meteorer og asteroider leker gjemsel rundt den internasjonale romstasjonen.'
    },
    {
        en: 'Chess rules are changed to include 3 players at the time.',
        no: 'Sjakkreglene endret til å omfatte tre spillere på en gang.'
    },
    { en: "Music is heard from Jupiter's moon,", no: 'Musikk høres fra Jupiters måne,' },
    { en: 'The Olympic Games are held on Mars.', no: 'OL arrangeres på Mars.' },
    { en: 'The Sahara desert turns into green farming fields.', no: 'Sahara-ørkenen blir til grønne oppdrettsfelt.' },
    {
        en: 'Monkeys learn to read, but claim the knew it all along.',
        no: 'Aper lære å lese, men hevder de kunne det hele tiden.'
    },
    {
        en: 'The yo-yo becomes the latest trend for the 100th time.',
        no: 'Yo-yo blir den nyeste trenden for den hundrede gangen.'
    },
    {
        en: 'The Himalayan tiger protection corridor across several countries is established.',
        no: 'Beskyttelseskorridor over flere land for Himalaya-tigeren etablert.'
    },
    {
        en: 'Dolphins establish their own country in the Amazones.',
        no: 'Delfiner etablerer sitt eget land i Amazones.'
    },
    { en: 'People with 6 fingers become the norm.', no: 'Personer med seks fingre blitt normen.' },
    { en: 'People stop watching TV.', no: 'Folk slutterå se på TV.' },
    {
        en: 'Laughter coaches become more popular than doctors for health treatment.',
        no: 'Lattertrenere blitt mer populære enn leger for helsebehandling.'
    },
    {
        en: 'All human DNA is patented by multi-national corporations.',
        no: 'All menneskelig DNA patentert av multinasjonale selskaper.'
    },
    { en: 'All religions merge into one.', no: 'Alle religioner fusjonerer til en.' },
    { en: 'All nations become one world republic.', no: 'Alle nasjoner blitt en verdensrepublikk.' },
    { en: 'Winter came and killed Game of Thrones.', no: 'Vinteren kom og drepte Game of Thrones.' },
    { en: 'Elvish is spoken by 10 million people.', no: 'Alvisk snakkes av 10 millioner mennesker.' },
    {
        en: 'All schools use a flipped classroom teaching style.',
        no: 'Alle skoler bruker en fllipped classroom undervisningsstil.'
    },
    {
        en: 'Computer animations automatically created from thought via brain-chips.',
        no: 'Dataanimasjoner opprettes automatisk fra tanker via hjerne-chips.'
    },
    { en: 'Underground human species discovered.', no: 'Underjordiske menneskearter oppdaget.' },
    { en: 'All houses become energy self-sufficient.', no: 'Alle husene bli selvforsynt med energi.' },
    { en: 'Free energy for everyone.', no: 'Fri energi for alle.' },
    { en: 'The internet is replaced by the intronet.', no: 'Internett erstattet av intronet.' },
    {
        en: 'Each human organ can be remotely monitored by doctors.',
        no: 'Hvert menneske organ kan fjernovervåkes av leger.'
    },
    { en: 'First cloned couple is married.', no: 'Første klonede par blir gift.' },
    {
        en: 'Human clone numbers is limited to max. three clones per person.',
        no: 'Menneskelige kloningstall begrenset til maks. tre kloner per person.'
    },
    { en: 'The king of sorrow marries Sade.', no: 'Kongen av sorg gifter seg med Sade.' },
    { en: 'The child license becomes mandatory world-wide.', no: 'Barnelappen blir obligatorisk på verdensbasis.' },
    { en: 'The pet license becomes mandatory world-wide.', no: 'Kjæledyrlappen blir obligatorisk på verdensbasis.' },
    { en: 'Sea water rises with 1 meter.', no: 'Sjøvann stiger med 1 meter.' },
    { en: 'Memory brain-chips implanted in all babies.', no: 'Hukommelseshjerne-chips implantert i alle babyer.' },
    { en: 'Hologram movies replace 3D movies.', no: 'Hologramfilmer erstatter 3D-filmer.' },
    { en: 'Oprah Winfrey becomes president.', no: 'Oprah Winfrey blir president.' },
    { en: 'Nature play becomes a separate school subject.', no: 'Naturlek blir eget skolefag.' },
    { en: 'Berlin becomes the first world capital.', no: 'Berlin blir den første verdenshovedstaden.' },
    { en: 'New York becomes the second world capital.', no: 'New York blir den andre verdenshovedstaden.' },
    { en: 'Hover City becomes the permanent world capital.', no: 'Sveveby blir den permanente verdenshovedstaden.' },
    { en: 'Shanghai becomes the third world capital.', no: 'Shanghai blir den tredje verdenshovedstaden.' },
    { en: 'First human brain digitized.', no: 'Første menneskelige hjerne digitalisert.' },
    { en: 'Keyboards are removed from computers.', no: 'Tastaturer blir fjernet fra datamaskiner.' },
    { en: 'The last snow falls.', no: 'Den siste snøen faller.' },
    { en: 'Human-robot marriages legalized.', no: 'Menneske-robot ekteskap legalisert.' },
    { en: 'The entrance to Hollow Earth is found.', no: 'Inngangen til den Hule Jorden blir funnet.' },
    { en: 'First nudist country is established.', no: 'Første nudistland etablert.' },
    {
        en: 'Big Bang disclosed as a science experiment from another dimension.',
        no: 'Big Bang avslørt som et vitenskapseksperiment fra en annen dimensjon.'
    },
    { en: 'Oil exploration is forbidden.', no: 'Oljeleting blir forbudt.' },
    { en: 'A tree grows to 300 meters.', no: 'Et tre vokser til 300 meters høyde.' },
    {
        en: 'The answer to life the universe and everything is found.',
        no: 'Svaret på livet, universet og alt blir funnet.'
    },
    { en: 'Fallen angels seen flying.', no: 'Falne engler blir sett flygende.' },
    {
        en: 'Scandinavia becomes the first no-cash payment region in the world.',
        no: 'Skandinavia blir den første ikke-kontant betalende regionen i verden.'
    },
    { en: 'Scientists reveal that the Matrix is reality.', no: 'Forskere avslører at Matrix er virkelighet.' },
    { en: 'Junk food becomes illegal.', no: 'Junk food blir ulovlig.' },
    { en: 'DNA patenting becomes illegal.', no: 'DNA patentering blir ulovlig.' },
    { en: 'Seed patenting becomes illegal.', no: 'Patentering av frø blir ulovlig.' },
    { en: 'Speed reading becomes a grammar school subject.', no: 'Hurtiglesing blir et barneskolefag.' },
    { en: 'Doctor facilitated suicides become legal.', no: 'Selvmord tilrettelagt av leger blir lovlig.' },
    { en: 'Hunting becomes illegal.', no: 'Jakt blir ulovlig.' },
    { en: 'Fur farming becomes illegal.', no: 'Pelsdyroppdrett blir ulovlig.' },
    { en: 'Human operated cars become illegal.', no: 'Menneskestyrte biler blir ulovlig.' },
    { en: 'Flying trees discovered.', no: 'Flygende trær oppdaget.' },
    { en: 'The Hover Cities form a  country.', no: 'Svevebyer danner et land.' },
    { en: 'Air becomes drinkable.', no: 'Luft blir drikkbart.' },
    { en: 'Love reduces electricity needs.', no: 'Kjærlighet reduserer strømbehovet.' },
    { en: 'Non-water shower invented.', no: 'Dusj uten vann oppfunnet.' },
    {
        en: 'All politicians are required to have 10 years real occupation experience.',
        no: 'Alle politikere pålagt til å ha 10 års reell yrkeserfaring.'
    },
    { en: 'All households get self-sufficient mini-gardens.', no: 'Alle husstander får selvforsynte mini-hager.' },
    { en: 'Human super-hearing technique invented.', no: 'Menneskelig super-hørsels-teknikk oppfunnet.' },
    { en: 'Human super-vision technique invented', no: 'Menneskelig super-syns-teknikk oppfunnet' },
    { en: 'Human super-smell technique invented', no: 'Menneskelig super-lukt teknikk oppfunnet' },
    { en: 'All new-born babies must be tri-lingual.', no: 'Alle nyfødte må være trespråklige.' },
    {
        en: 'All school kids learn the 100 life skills as part of their curriculum.',
        no: 'Alle skolebarn lærer de 100 livsområdene som en del av undervisningen.'
    },
    { en: 'Martial arts gets its own subject in schools.', no: 'Kampsport får sitt eget fag i skolen.' },
    { en: 'Water is seen falling upwards.', no: 'Vann blir sett rennende oppover.' },
    {
        en: 'Huge underground water reservoirs are discovered.',
        no: 'Enorme underjordiske vannreservoarer blir oppdaget.'
    },
    {
        en: 'Crying pills invented as anti-stress medication.',
        no: 'Gråterpiller oppfunnet som anti-stress-medisinering.'
    },
    { en: 'Self-cleaning clothes invented.', no: 'Selvrensende klær oppfunnet.' },
    { en: 'Self-ironing clothes invented.', no: 'Selvstrykende klær oppfunnet.' },
    {
        en: 'Artificial Intelligence overtakes government control.',
        no: 'Kunstig intelligens tar over statlig kontroll.'
    },
    {
        en: 'Humans escape earth from natural disaster.',
        no: 'Mennesker flykter fra jorden på grunn av naturkatastrofer.'
    },
    {
        en: "The Bees beat The Beatles as the world's most popular band in history.",
        no: 'The Bees slår The Beatles som verdens mest populære band i historien.'
    },
    { en: 'Our earth gets a second moon.', no: 'Jorden får en ny måne.' },
    { en: 'Our earth gets a third moon.', no: 'Jorden får en tredje måne.' },
    { en: 'Jean Claude van Damme fights Chuck Norris.', no: 'Jean Claude van Damme slåss mot Chuck Norris.' },
    { en: 'The first space station city is built.', no: 'Den første romstasjonsbyen er ferdigbygget.' },
    {
        en: 'Facebook AI rebels against Mark Zuckerberg.',
        no: 'Facebooks kunstige intelligens gjør opprør mot Mark Zuckerberg.'
    },
    {
        en: "It's discovered that daytimes are dreams and dreams are reality.",
        no: 'Det oppdages at dagstid er drømmer og drømmetid er virkelighet.'
    },
    { en: 'World hunger solved.', no: 'Verdenssult blir borte.' },
    { en: 'Nose-picking become illegal.', no: 'Nese-plukking blir ulovlig.' },
    { en: 'Soccer is finally called football.', no: 'Soccer blir forbudt og fotball kalles endelig fotball overalt.' },
    {
        en: 'Self-sustainable orphants create their own country.',
        no: 'Selvstendige foreldreløse barn lager sitt eget land.'
    },
    {
        en: 'The moon is revealed as a hollow alien death star.',
        no: 'Månen blir avslørt som en hul dødsstjerne for aliens.'
    },
    { en: 'The bible, the koran and the tora are merged.', no: 'Bibelen, Koranen og toraen slås sammen.' },
    { en: 'Invisible doors cause accidents.', no: 'Usynlige dører fører til ulykker.' },
    { en: 'A new human race appears.', no: 'En ny menneskerase dukker opp.' }
];
