export function stringifyDate(date) {
    let str = '';
    if (date.year) {
        str += date.year;
    }
    if (date.month) {
        str += '/' + date.month;
    }
    if (date.day) {
        str += '/' + date.day;
    }
    return str;
}
export function stringifyTime(time) {
    let str = '';
    if (time.date) {
        str += stringifyDate(time.date);
    }
    if (time.endDate) {
        str += ' — ' + stringifyDate(time.endDate);
    }
    return str;
}
